/* eslint-disable no-unused-vars */
import { returnErrors } from './errorAction';
import { getApiCall, postApiCall } from '../../utils/request';
import {
    RESET_CLASSROOMS, GET_OTHER_SUBJECT, GET_OTHER_SKILLS, SEARCH_SKILLS, GET_SKILL_DETAILS, GET_SKILL_PODS, GET_COURSES, RESET_TOPIC_LOADING, GET_POD_RESOURCES, RESET_CONTENT_LOADING, GET_RESOURCE_FROM_CONTENT,
    Skill_Analytic_Capture, LIKE_CONTENT_RESOURCE, GET_CONTENT_NOTE, SAVE_CONTENT_NOTE, BACK_TO_TOPIC,
    GET_BOOKMARKED_SKILL, TOGGLE_BOOKMARK, BOOKMARK_DONE,
    GET_TEST_QUESTIONS, GET_TEST_QUESTION, SELECT_ANSWER, TEST_SUBMIT, CLOSE_TEST, GET_CERT_DATA
} from '../Types/types';

export const resetClassrooms = () => (dispatch) => {
    dispatch({
        type: RESET_CLASSROOMS
    });
}

export const getSubjectByClass = (cls) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/learning/class/subjects/list?class=${cls}`);
        if (response.data.data) {
            const data = response.data.data
            const subjects = data.map(item => ({ ...item, class: cls }))
            dispatch({ type: GET_OTHER_SUBJECT, payload: subjects });
        }
        else {
            dispatch({ type: GET_OTHER_SUBJECT, payload: [] });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getOtherSkills = () => async (dispatch) => {
    try {
        const response = await getApiCall(`student/library/skill`);
        dispatch({ type: GET_OTHER_SKILLS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

//Content library

export const getSkillDetails = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/library/skill/details?skill_id=${id}`);
        dispatch({ type: GET_SKILL_DETAILS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getSkillPods = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/library/skill/pods?skill_id=${id}`);
        return dispatch({ type: GET_SKILL_PODS, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getCourseList = (id, type) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/library/skill/additional/resources?type=${type}&skill_id=${id}`);
        return dispatch({ type: GET_COURSES, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetSkillPodLoading = () => (dispatch) => {
    dispatch({
        type: RESET_TOPIC_LOADING
    });
}

export const getPodResources = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/library/skill/pod/resources?pod_id=${id}`);
        const data = response.data.data;
        return dispatch({ type: GET_POD_RESOURCES, payload: { data, id } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const resetContentLoading = () => (dispatch) => {
    dispatch({
        type: RESET_CONTENT_LOADING
    });
}

export const getResourceFromContent = (type, id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/library/skill/pod/resource/details?resource_id=${id}&type=${type}`);
        dispatch({ type: GET_RESOURCE_FROM_CONTENT, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const viewAnalytic = (analytic) => async (dispatch) => {
    try {
        const response = await postApiCall('student/library/skill/analytic', analytic);
        dispatch({ type: Skill_Analytic_Capture, payload: { ...response.data.data, ...analytic } });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status);
    }

}


export const likeResource = (obj) => async (dispatch) => {
    try {
        await postApiCall('student/learning/resource/like', obj);
        returnErrors(dispatch, obj.like ? `${obj.type} has been liked` : `${obj.type} has been unliked`, 200);
        dispatch({ type: LIKE_CONTENT_RESOURCE, payload: obj });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}
export const getNote = (id) => async (dispatch) => {

    try {
        const response = await getApiCall(`student/learning/resource/note?resource_id=${id}`);
        dispatch({ type: GET_CONTENT_NOTE, payload: response.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const savedNote = (save) => async (dispatch) => {

    try {
        await postApiCall('student/learning/resource/note', save);
        dispatch({ type: SAVE_CONTENT_NOTE, payload: save });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const backToTopic = () => {
    return {
        type: BACK_TO_TOPIC
    };
}

export const getSearchResults = (val) => (dispatch) => {
    dispatch({
        type: SEARCH_SKILLS, payload: val
    });
}

export const getBookmarkedSkill = () => async (dispatch) => {
    try {
        const response = await getApiCall(`student/learning/content/bookmark`);
        dispatch({ type: GET_BOOKMARKED_SKILL, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}


export const bookmarksSkill = (bookmark, id, aff_id) => async (dispatch) => {
    const obj = {
        _id: id
    }
    try {
        const response = await postApiCall(`student/learning/content/bookmark/toggle`, obj);
        returnErrors(dispatch, bookmark ? 'removed from Saved' : 'added to Saved', response?.status);
        dispatch({ type: TOGGLE_BOOKMARK, payload: { bookmark, id, aff_id } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getTestdata = (id, title) => async (dispatch) => {
    let obj = { skill_id: id }
    try {
        const response = await postApiCall(`student/library/skill/test/start`, obj);
        const res = response.data.data
        if (response.status === 200) {
            return dispatch({ type: GET_TEST_QUESTIONS, payload: { ...res, title: title } });
        } else {
            // returnErrors(dispatch, response?.data?.message, response?.status);
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const getQuestion = (ques) => async (dispatch) => {
    return dispatch({
        type: GET_TEST_QUESTION,
        payload: ques
    });
}


export const selectAnswer = (qid, aid) => async (dispatch) => {
    try {
        dispatch({ type: SELECT_ANSWER, payload: { aid, qid } });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}

export const testSubmitted = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall('student/library/skill/test/submit', obj);
        dispatch({ type: TEST_SUBMIT, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }

}

export const closeQuiz = () => async (dispatch) => {
    dispatch({ type: CLOSE_TEST })
}

export const getCertificat = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`student/library/skill/test/report?skill_id=${id}`);
        return dispatch({ type: GET_CERT_DATA, payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data?.message, error?.response?.status);
    }
}











export const getIndex = (type, id) => async (dispatch) => {

    try {
        const response = await getApiCall(`student/my-classroom/resource/list?module=${type}&_id=${id}`);
        dispatch({ type: "Get_Individual_Skill_Resource", payload: response.data.data });

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status);
    }
}

export const resetIndex = () => async (dispatch) => {
    dispatch({
        type: "Reset_Skill_Index"
    });
}

export function resetPodLoading() {
    return {
        type: "reset_index"
    };
}
export function getPrevIndex(el) {
    return {
        type: "Get_Previous_Skill_Index", payload: el
    };
}



export const reTake = (_id) => async (dispatch) => {
    const retakeId = {
        _id
    }
    try {
        await postApiCall('student/my-classroom/resource/retake-quiz', retakeId);
        dispatch({ type: "Skill_Retake_Quiz", payload: _id });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status);
    }
}

export const shareFeed = (data) => async (dispatch) => {
    return dispatch({
        type: 'Share_Popup_Open',
        payload: data
    })
}

export const shareModalClose = () => async (dispatch) => {
    return dispatch({
        type: 'Share_Popup_Close'
    })
}
export const shareAll = (resource) => async (dispatch) => {
    try {
        const response = await postApiCall('notice-board/store-data', resource);
        if (response.data.success) {
            dispatch({ type: "Share_Done", payload: response.data });
            returnErrors(dispatch, `${resource.resource_type} is shared`, response.status);
        }

    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status);
    }
}

export const getSearchSkill = (val) => async (dispatch) => {
    try {
        const response = await getApiCall(`skill-path/search?query=${val}`);
        dispatch({ type: "Search_Skill", payload: response.data.data });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status);
    }
}

