import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRewardPoints, getRewardList, openRedeemModal, closeRedeemModal, checkRedeem, getRewardHistory, redeemCoupon, closeGreetModal } from '../../Redux/ActionCreators/rewardAction';
import { Modal } from 'react-bootstrap';
import { validatePhone } from '../../utils/miscelleneous';
import { BallBeat } from '../../Loader/loader';
import './rewards.scss';

export default function Contests() {
    const [activeBtn, setActiveBtn] = useState('overview');
    const [openEnoughCoinModal, setOpenEnoughCoinModal] = useState(false);
    const [mobile, setMobile] = useState('');
    const [mobilevalidError, setMobileValidError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { points, country, currency, rewards, rewardHistory, isOpenRedeem, cardDetails, greetModal, score } = useSelector(state => state.reward);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        if (location.search) {
            if (location.search.split('=')[1] === 'redeem') {
                setActiveBtn('redeem');
                dispatch(getRewardList());
                dispatch(getRewardHistory());
            }
        }
        dispatch(getRewardPoints());
    }, [])

    const onBtnchange = (e) => {
        setActiveBtn(e);
        if (e === 'overview') {
            dispatch(getRewardPoints());
        }
        else if (e === 'redeem') {
            dispatch(getRewardList());
            window.innerWidth > 767 && dispatch(getRewardHistory());
        }
        else if (e === 'history') {
            dispatch(getRewardHistory());
        }
    }


    const handleCloseModal = async () => {
        await dispatch(closeRedeemModal());
        setMobileValidError(false);
        setErrorMessage('');
        setMobile('');
        setIsLoading(false);
    }

    const redeemCard = (reward) => {
        setMobile(reward.mobile);
        dispatch(openRedeemModal(reward));
    }

    const changeMobile = (val) => {
        setMobile(val);
        setMobileValidError(false);
        setErrorMessage('');
    }

    const couponRedeem = async (details) => {
        setIsLoading(true);
        const redeem = await dispatch(checkRedeem(details?._id, details?.contest?._id));

        if (redeem.payload) {

            let mobilevalue = mobile
            if (cardDetails?.provider?.title === 'Paytm') {
                if (mobile !== null && mobile?.toString().length !== 10) {
                    setMobileValidError(true);
                    setErrorMessage('Please enter 10 digit mobile number.');
                }
                else if (!validatePhone(mobilevalue)) {
                    setMobileValidError(true);
                    setErrorMessage('Please enter correct mobile number');
                }
                else {
                    let obj = {
                        reward_id: details?._id, contest_id: details?.contest?._id, mobile: mobile
                    }
                    await dispatch(redeemCoupon(obj));
                    await dispatch(getRewardList());
                }
            } else {
                let obj = {
                    reward_id: details?._id, contest_id: details?.contest?._id
                }
                await dispatch(redeemCoupon(obj));
                await dispatch(getRewardList());
            }
            await dispatch(getRewardHistory());
        } else {
            setOpenEnoughCoinModal(true);
        }
        await setIsLoading(false);
    }

    const handleCloseModal1 = async () => {
        setOpenEnoughCoinModal(false);
    }


    const goToRedeem = () => {
        setActiveBtn('redeem');
        dispatch(getRewardList());
        dispatch(getRewardHistory());
    }


    return (
        <div>
            {
                window.location.origin === 'https://learn.newhorizongurukul.in' ?
                    <div className="contest-main">
                        <div align="center" style={{ width: "600px", marginInline: "auto" }}>
                            <div
                                style={{
                                    padding: "15px 10px",
                                    display: "flex",
                                    alignItems: "flex-start",
                                }}
                            >
                                <img src="/images/logo-green.png" alt="" width={188} height={40} />
                            </div>
                            <div style={{ background: "#fefefe" }}>
                                <img src="/images/Shikshasetu-img.png" alt="" />
                            </div>
                            <div style={{ padding: "0 15px", background: "#fefefe" }}>
                                <h3 className="join-contest-text">
                                    <span
                                        className="join-contest-text-span"
                                        style={{ color: "#376847" }}
                                    >
                                        Join the NHG Shikshasetu Contest – Win Exciting Prizes!
                                    </span>
                                </h3>
                                <div style={{ paddingTop: "15px" }}>
                                    <p className="join-contest-text-para">Dear Student</p>
                                    <br />
                                    <p className="join-contest-text-para">
                                        We’re excited to announce the{" "}
                                        <span
                                            className="join-contest-text-span"
                                            style={{ color: "#376847" }}
                                        >
                                            NHG Shikshasetu Contest
                                        </span>{" "}
                                        starting from{" "}
                                        <span
                                            className="join-contest-text-span"
                                            style={{ color: "#376847" }}
                                        >
                                            November 6th to December 15th!
                                        </span>{" "}
                                        Here’s your chance to learn, practice, and win amazing prizes.
                                    </p>
                                </div>
                                <div style={{ paddingTop: "15px" }}>
                                    <h4 className="join-contest-text2">How to Win:</h4>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "15px",
                                            padding: "20px 0",
                                        }}
                                    >
                                        <img src="/images/old-computer.png" alt="" />
                                        <p className="join-contest-text-para">
                                            <span
                                                className="join-contest-text-span"
                                                style={{ color: "#376847" }}
                                            >
                                                <strong>
                                                    Complete watching the learning content for your grade.
                                                </strong>
                                            </span>
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "15px",
                                            padding: "20px 0",
                                        }}
                                    >
                                        <img src="/images/medal.png" alt="" />
                                        <p className="join-contest-text-para">
                                            <span
                                                className="join-contest-text-span"
                                                style={{ color: "#376847" }}
                                            >
                                                <strong>
                                                    Ace the Practice Tests and be among the top scorers.
                                                </strong>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div style={{ paddingTop: "15px" }}>
                                    <h4 className="join-contest-text2">
                                        <span
                                            className="join-contest-text-span"
                                            style={{ color: "#376847" }}
                                        >
                                            Prizes:
                                        </span>
                                    </h4>
                                    <p className="join-contest-text-para">
                                        The
                                        <strong> top three performers from each grade </strong>
                                        will win exciting prizes! Increase your engagement score and be on
                                        the top.
                                    </p>
                                </div>
                                <div style={{ paddingTop: "15px" }}>
                                    <div style={{ padding: "15px" }}>
                                        <button className="contest-btn" onClick={() => navigate('/my-learning')} style={{ height: 40, background: "#376847" }}>
                                            Gear up and start learning
                                        </button>
                                    </div>
                                    <p
                                        className="join-contest-text-para"
                                        style={{ padding: "15px 0", textAlign: "center" }}
                                    >
                                        <span
                                            className="join-contest-text-span"
                                            style={{ color: "#376847" }}
                                        >
                                            <strong>We can’t wait to see you shine!</strong>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    window.location.origin === 'https://learn.nhps.in' ?
                        <div className="contest-main">
                            <div align="center" style={{ width: "600px", marginInline: "auto" }}>
                                <div
                                    style={{
                                        padding: "15px 10px",
                                        display: "flex",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <img
                                        src="/images/newhorizon-logo.png"
                                        alt=""
                                        width={188}
                                        height={40}
                                    />
                                </div>
                                <div style={{ background: "#fefefe" }}>
                                    <img src="/images/dashboard-blue.png" alt="" width={600} />
                                </div>
                                <div style={{ padding: "0 15px", background: "#fefefe" }}>
                                    <h3 className="join-contest-text">
                                        <span
                                            className="join-contest-text-span"
                                            style={{ color: "#332e59" }}
                                        >
                                            Join the NHPS iLearn Contest – Win Exciting Prizes!
                                        </span>
                                    </h3>
                                    <div style={{ paddingTop: "15px" }}>
                                        <p className="join-contest-text-para">Dear Student</p>
                                        <br />
                                        <p className="join-contest-text-para">
                                            We’re excited to announce the{" "}
                                            <span
                                                className="join-contest-text-span"
                                                style={{ color: "#332e59" }}
                                            >
                                                NHPS iLearn Contest
                                            </span>{" "}
                                            starting from{" "}
                                            <span
                                                className="join-contest-text-span"
                                                style={{ color: "#332e59" }}
                                            >
                                                6th November to 15th December!
                                            </span>{" "}
                                            Here’s your chance to learn, practice, and win amazing prizes.
                                        </p>
                                    </div>
                                    <div style={{ paddingTop: "15px" }}>
                                        <h4 className="join-contest-text2">How to Win:</h4>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "15px",
                                                padding: "20px 0",
                                            }}
                                        >
                                            <img src="/images/old-computer.png" alt="" />
                                            <p className="join-contest-text-para">
                                                <span
                                                    className="join-contest-text-span"
                                                    style={{ color: "#332e59" }}
                                                >
                                                    <strong>
                                                        Complete watching the learning content for your grade.
                                                    </strong>
                                                </span>
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "15px",
                                                padding: "20px 0",
                                            }}
                                        >
                                            <img src="/images/medal.png" alt="" />
                                            <p className="join-contest-text-para">
                                                <span
                                                    className="join-contest-text-span"
                                                    style={{ color: "#332e59" }}
                                                >
                                                    <strong>
                                                        Ace the Practice Tests and be among the top scorers.
                                                    </strong>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ paddingTop: "15px" }}>
                                        <h4 className="join-contest-text2">
                                            <span
                                                className="join-contest-text-span"
                                                style={{ color: "#332e59" }}
                                            >
                                                Prizes:
                                            </span>
                                        </h4>
                                        <p className="join-contest-text-para">
                                            The
                                            <strong> top three performers from each grade </strong>
                                            will win exciting prizes! Increase your engagement score and be on
                                            the top.
                                        </p>
                                    </div>
                                    <div style={{ paddingTop: "15px" }}>
                                        <div style={{ padding: "15px" }}>
                                            <button className="contest-btn" onClick={() => navigate('/my-learning')} style={{ height: 40, background: "#332e59" }}>
                                                Gear up and start learning
                                            </button>
                                        </div>
                                        <p
                                            className="join-contest-text-para"
                                            style={{ padding: "15px 0", textAlign: "center" }}
                                        >
                                            <span
                                                className="join-contest-text-span"
                                                style={{ color: "#332e59" }}
                                            >
                                                <strong>We can’t wait to see you shine!</strong>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='elite-rewards-holder'>

                            <h1>Rewards</h1>
                            <div className='elite-rewards-btns'>
                                <button className={activeBtn === 'overview' ? 'active-btn' : ''} onClick={() => onBtnchange('overview')}>Overview</button>&nbsp;
                                <button className={activeBtn === 'redeem' ? 'active-btn' : ''} onClick={() => onBtnchange('redeem')}>Redeem</button>&nbsp;
                                {/* <button className={activeBtn === 'faq' ? 'active-btn' : ''} onClick={() => onBtnchange('faq')}>FAQ</button>&nbsp; */}
                                {window.innerWidth > 767 ? null : < button className={activeBtn === 'history' ? 'active-btn' : ''} onClick={() => onBtnchange('history')}>History</button>}
                            </div>
                            {
                                activeBtn === 'overview' ?
                                    <div className='elite-rewards-overview'>
                                        {
                                            window.innerWidth > 767 ?
                                                <div className='reward-coins'>
                                                    <div className={window.innerWidth > 767 ? 'row align-items-center' : 'row'}>
                                                        <div className='col-md-4'>
                                                            <img src='/images/rewards.svg' alt='' />
                                                        </div>
                                                        <div className='col-md-8'>
                                                            <div className='row '>
                                                                <div className='col-md-5 d-flex align-items-center'>
                                                                    <img src='/images/coin.svg' alt='' />&nbsp;&nbsp;
                                                                    <div style={{ width: '40%' }}>
                                                                        <p>{score?.balance_score}</p>
                                                                        <small className='body-text'>Current Elite Points</small>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4 d-flex justify-content-between align-items-center'>
                                                                    <div className='lifetime-coin'>
                                                                        <img src='/images/coin.svg' alt='' />
                                                                        <p style={{ fontSize: 24 }}>{score?.eng_score}</p>
                                                                        <small className='body-text'>Earned</small>
                                                                    </div>
                                                                    <div className='lifetime-coin'>
                                                                        <img src='/images/coin.svg' alt='' />
                                                                        <p style={{ fontSize: 24 }}>{score?.eng_score - score?.balance_score}</p>
                                                                        <small className='body-text'>Redeemed</small>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className='row mt-3 ml-3'>
                                                                <button className='redeem' onClick={goToRedeem}>Redeem Points</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div> :
                                                <div className='reward-coins'>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <img src='/images/rewards1.svg' alt='' />
                                                        </div>
                                                        <div className='col-6 mt-3'>
                                                            <div>
                                                                <div className='d-flex align-items-center'>
                                                                    <img src='/images/coin.svg' alt='' width='64px' height='64px' />&nbsp;&nbsp;
                                                                    <p>{score?.balance_score}</p>
                                                                </div>
                                                                <small className='body-text pl-2'>Current Elite Points</small>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <button className='form-control button-color mt-2 mb-2' style={{ width: '93%', color: '#fff', margin: 12, backgroundColor: '#f5821f' }} onClick={goToRedeem} >Redeem Points</button>
                                                    <div className='row'>
                                                        <div className='col-12 pl-4 pr-4 d-flex justify-content-around align-items-center'>
                                                            <div className='lifetime-coin'>
                                                                <img src='/images/coin.svg' alt='' />
                                                                <p style={{ fontSize: 24 }}>{score?.eng_score}</p>
                                                                <small className='body-text'>Earned</small>
                                                            </div>
                                                            <div className='lifetime-coin'>
                                                                <img src='/images/coin.svg' alt='' />
                                                                <p style={{ fontSize: 24 }}>{score?.eng_score - score?.balance_score}</p>
                                                                <small className='body-text'>Redeemed</small>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                        }

                                        <div className='earn-coins'>
                                            <h1>How To Earn Reward Points</h1>
                                            {
                                                window.innerWidth > 767 ?
                                                    <div className='row mt-5 justify-content-between'>
                                                        <div className='col-md-3'>
                                                            <img src='/images/article-icon.svg' alt='' width={50} />
                                                            <p style={{ marginTop: 8 }}>Read Articles</p>
                                                            <small className='body-text'>Earn 2 reward points for every article you read</small>
                                                        </div>
                                                        <div className='col-md-3 pl-3'>
                                                            <img src='/images/video-icon.svg' alt='' width={50} />
                                                            <p style={{ marginTop: 8 }}>Watch Videos</p>
                                                            <small className='body-text'>Earn 2 reward points for every video you watch</small>
                                                        </div>
                                                        <div className='col-md-3 pl-3'>
                                                            <img src='/images/quiz-icon.svg' alt='' width={50} />
                                                            <p style={{ marginTop: 8 }}>Complete Quizzes</p>
                                                            <small className='body-text'>Earn 2 reward points for every quiz you complete</small>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <img src='/images/checkin-rewards.svg' alt='' />
                                                            <p style={{ marginTop: 6 }}>Check-In Daily</p>
                                                            <small className='body-text'>Earn 1 reward point for daily check-ins, make learning a habit</small>
                                                        </div>
                                                    </div> :
                                                    <div className='row mt-4'>
                                                        <div className='col-md-3 d-flex align-items-center'>
                                                            <img src='/images/article-icon.svg' alt='' width={56} />
                                                            <div className='pl-3'>
                                                                <p>Read Articles</p>
                                                                <small className='body-text'>Earn 2 reward points for every article you read</small>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3 mt-3 d-flex align-items-center'>
                                                            <img src='/images/video-icon.svg' alt='' width={56} />
                                                            <div className='pl-3'>
                                                                <p>Watch Videos</p>
                                                                <small className='body-text'>Earn 2 reward points for every video you watch</small>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3 mt-3 d-flex align-items-center'>
                                                            <img src='/images/quiz-icon.svg' alt='' width={56} />
                                                            <div className='pl-3'>
                                                                <p>Complete Quizzes</p>
                                                                <small className='body-text'>Earn 2 reward points for every quiz you complete</small>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3 mt-3 d-flex align-items-center'>
                                                            <img src='/images/checkin-rewards.svg' alt='' />
                                                            <div className='pl-3'>
                                                                <p>Check-In Daily</p>
                                                                <small className='body-text'>Earn 1 reward point for daily check-ins, make learning a habit</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    activeBtn === 'redeem' ?
                                        (<div className='redeem-header'>
                                            <div className="redeem-holder">
                                                <div>
                                                    <div className="" >
                                                        {
                                                            rewards && rewards?.length > 0 ?
                                                                <div className={window.innerWidth > 767 ? 'row row-cols-4' : 'row'}>
                                                                    {
                                                                        (rewards && rewards?.length > 0) &&
                                                                        rewards?.map((rewards, index) => {
                                                                            return (
                                                                                <div className={window.innerWidth > 767 ? "col pr-0 mt-1 mb-2 d-flex align-self-stretch mb-3 pl-2" : 'card-align col-12 mt-1 mb-2 d-flex align-self-stretch'} onClick={() => redeemCard(rewards)}>
                                                                                    <div key={index} className="card reward-card">
                                                                                        <div className='mobile-card' style={{ position: 'relative', background: rewards?.provider?.bgcolor }}>
                                                                                            <img className="card-img-top" src={rewards.provider?.image} alt='' style={{ width: '100%', height: 88 }} />
                                                                                        </div>
                                                                                        <div className="card-body">
                                                                                            <p className="card-text">{rewards.country?.currency} {rewards?.value}<br />{rewards?.reward_title}</p>
                                                                                            <h2 className='d-flex justify-content-center align-items-center mt-3'><img src='/images/coin.svg' alt='' width={24} height={24} /> &nbsp; {rewards.cost}</h2>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })

                                                                    }
                                                                </div> :
                                                                <div className='empty-list'>
                                                                    <img src='/images/empty.png' alt='' />
                                                                    <p>Rewards will be available only during contest period.</p>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='history-desktop'>
                                                <div className="reward-history1">
                                                    <h1 className="border-bottom p-3">Rewards History </h1>
                                                    <div className='rewards-history'>
                                                        <ul style={{
                                                            height: window.innerWidth > 767 ? 400 : 510,
                                                            overflow: 'auto',
                                                            overflowX: 'hidden', borderBottomLeftRadius: 4, borderBottomRightRadius: 4
                                                        }} id="scrollableDiv1">
                                                            {rewardHistory?.length > 0 ?
                                                                rewardHistory !== undefined && rewardHistory.map((history, index) => {
                                                                    return (
                                                                        <li className="reward-entry">
                                                                            <div>
                                                                                <h1>{history.title}</h1>
                                                                                <p>{'Debited on '}{new Date(history.createdAt).toDateString()}</p>
                                                                            </div>
                                                                            <h2 className='credit' style={{ color: '#E8325D' }}>
                                                                                {'-' + history.cost}
                                                                                {/* {history.credit ? '+' + history.points : '-' + history.points} */}
                                                                            </h2>
                                                                        </li>
                                                                    )
                                                                })
                                                                :
                                                                <h2 className='text-center mt-2'>
                                                                    There is no rewards history yet
                                                                </h2>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        ) :
                                        activeBtn === 'faq' ?
                                            <div className="faq">
                                                <div className='row' style={{ flexDirection: window.innerWidth > 767 ? '' : 'column-reverse' }}>
                                                    <div className='col-md-7'>
                                                        <div className='faq-question p-2'>
                                                            <h2 className='body-text'>Q. What are Elite Points?</h2>
                                                            <p className='body-text'>Elite Points are a virtual currency that can be used to redeem exciting offers/vouchers on Elite.</p>
                                                        </div>
                                                        <div className='faq-question p-2'>
                                                            <h2 className='body-text'>Q. How can I earn Elite Points?</h2>
                                                            <p className='body-text'>You can earn Elite points through different activities- daily check-ins, article reads, video views, and more. Every engagement score gained is equal to one Elite point.
                                                            </p>
                                                        </div>
                                                        <div className='faq-question p-2'>
                                                            <h2 className='body-text'>Q. What are Daily Check-In Rewards?</h2>
                                                            <p className='body-text'>Log in to Elite daily and earn Elite Points for each visit. Interestingly, if you visit consecutively for 5 days, you get bonus Elite Points. Missing a day won't exhaust all your earnings.</p>
                                                        </div>
                                                        <div className='faq-question p-2'>
                                                            <h2 className='body-text'>Q. How do I earn points by Reading an article?</h2>
                                                            <p className='body-text'>You earn Elite Points every time you read an article. In case of quick swipes on articles, Elite Points may not get attributed.</p>
                                                        </div>
                                                        <div className='faq-question p-2'>
                                                            <h2 className='body-text'>Q. How do I earn points by Watching a video?</h2>
                                                            <p className='body-text'>You earn Elite Points every time you watch a video. In case you close the video too soon, Elite Points may not be attributed.</p>
                                                        </div>
                                                        <div className='faq-question p-2'>
                                                            <h2 className='body-text'>Q. How do I redeem Elite Points?</h2>
                                                            <p className='body-text'>In order to redeem the Elite Points, please visit the Redeem section for different offers.</p>
                                                        </div>
                                                        <div className='faq-question p-2'>
                                                            <h2 className='body-text'>Q. What are Current Elite Points?</h2>
                                                            <p className='body-text'>Current Elite Points are the total eligible points that can be used for redemption against the exciting offers.</p>
                                                        </div>
                                                        <div className='faq-question p-2'>
                                                            <h2 className='body-text'>Q. Are offers the same all the time?</h2>
                                                            <p className='body-text'>We work hard to revise the offers day in, day out to add more value and provide better options for redemption.</p>
                                                        </div>
                                                        <div className='faq-question p-2'>
                                                            <h2 className='body-text'>Q. How long does it take to receive the voucher?</h2>
                                                            <p className='body-text'>After redemption, you will receive the voucher within 2 business days.</p>
                                                        </div>
                                                        <div className='faq-question p-2'>
                                                            <h2 className='body-text'>Q. How many gift cards can I redeem each month?</h2>
                                                            <p className='body-text'>You can redeem upto two popular gift cards each month. This is in addition to the bonus gift cards. So keep learning and reep the rewards.</p>
                                                        </div>
                                                        <div className='faq-question p-2'>
                                                            <h2 className='body-text'>Q. What are upcoming Elite points?</h2>
                                                            <p className='body-text'>Your Elite points get updated every 24 hours. The upcoming Elite points refer to the reward points that will get added in the next update.</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-5'>
                                                        <img src='/images/faq-image.svg' alt='' width={window.innerWidth > 767 ? 380 : 360} height={400} />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="d-md-none d-lg-none d-xl-none reward-history">
                                                {/* <h1 className="border-bottom p-3">Rewards History</h1> */}
                                                <div className='rewards-history'>
                                                    <ul>
                                                        {
                                                            (rewardHistory !== undefined && rewardHistory?.length) ?
                                                                rewardHistory.map((history, index) => {
                                                                    return (
                                                                        <li className="reward-entry">
                                                                            <div>
                                                                                <h1>{history.title}</h1>
                                                                                <p>{'Debited on '}{new Date(history.createdAt).toDateString()}</p>
                                                                            </div>
                                                                            <h2 className='credit' style={{ color: '#E8325D' }}>
                                                                                {'-' + history.cost}
                                                                                {/* {history.credit ? '+' + history.points : '-' + history.points} */}
                                                                            </h2>
                                                                        </li>
                                                                    )
                                                                }) :
                                                                <h2 className='text-center mt-2'>
                                                                    <p className='pt-5'>There is no rewards history yet</p>
                                                                </h2>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                            }
                            {/* ############### Redeem Modal ############### */}
                            <Modal
                                show={isOpenRedeem}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                dialogClassName={"modal-60w"}
                                style={{ backdropFilter: 'none' }}
                            >
                                {
                                    cardDetails && <Modal.Body>
                                        <h1>Redeem Gift Card</h1>
                                        <div className="sticky-close" onClick={handleCloseModal}>
                                            <img
                                                src="/images/close1.svg" width='15px' alt='' />
                                        </div>
                                        <p className='text-uppercase'>{cardDetails && (cardDetails?.reward_title)}</p>
                                        <div className='reward-image'>
                                            <div className='d-flex align-items-center flex-column'>
                                                <img src={cardDetails?.provider?.image} height={66} width={172} alt='' />
                                                <div className='d-flex align-items-center mt-2'>
                                                    <div>
                                                        <div className='value'>
                                                            <img src='/images/rupee.svg' alt='' /> &nbsp;&nbsp;{cardDetails?.value}
                                                        </div>
                                                        <small>Value</small>
                                                    </div>&nbsp;&nbsp;
                                                    {/* <div>
                                                    <div className='value elipsis'>
                                                        <img src='/images/clock2.svg' alt='' />&nbsp;&nbsp;{cardDetails?.validity}
                                                    </div>
                                                    <small>Validity</small>
                                                </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='offer-details'>
                                            <h2>Offer Details</h2>
                                            <p className="description" dangerouslySetInnerHTML={{
                                                __html: cardDetails?.description
                                            }} />
                                        </div>
                                        <div className="pt-2 pb-0 text-center">
                                            {
                                                cardDetails?.provider?.title === 'Paytm' ?
                                                    <Fragment>
                                                        <div style={{ width: '98%', textAlign: 'left', padding: '5px 48px' }}>
                                                            <input type='number' autoComplete='off' id='mob' placeholder='Enter your mobile number' className='form-control' maxLength="10" pattern="[6789][0-9]{9}" onChange={(e) => changeMobile(e.target.value)} value={mobile} />
                                                            {mobilevalidError ? <span style={{ color: 'red', fontSize: 13, paddingBottom: 2 }}>{errorMessage}</span> : null}
                                                        </div>
                                                    </Fragment>
                                                    : null
                                            }
                                        </div>
                                        <div style={{ padding: '5px 42px' }}>
                                            <button style={{ backgroundColor: '#f5821f', position: 'relative' }} disabled={!!isLoading} onClick={() => couponRedeem(cardDetails)} className="redeem-btn">{isLoading ? <BallBeat /> : 'Redeem Gift Card'}</button>
                                        </div>
                                    </Modal.Body>
                                }

                            </Modal>


                            {/* Not Enough coin modal */}
                            <Modal
                                show={openEnoughCoinModal}
                                size={window.innerWidth > 767 ? "md" : "sm"}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                onHide={handleCloseModal1}
                                dialogClassName={"sm-modal"}
                                style={{ backdropFilter: 'none' }}
                            >
                                <div className='close-sticky-holder'>
                                    <img onClick={handleCloseModal1} src='/images/close1.svg' alt='' />
                                </div>
                                <Modal.Body style={{ padding: '20px' }}>
                                    <div className="enough-coins text-center">
                                        <h1>Not Enough points</h1>
                                        <p>Earn more points to claim the rewards</p>
                                    </div>

                                </Modal.Body>
                            </Modal>

                            {/* ----------greet modal ----------------- */}
                            <Modal
                                show={greetModal}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                // onHide={handleCloseModal}
                                dialogClassName={"modal-30w"}
                                style={{ backdropFilter: 'none' }}
                            >
                                <Modal.Body>
                                    <div className='close-sticky-holder'>
                                        <img onClick={() => dispatch(closeGreetModal())} src='/images/close1.svg' alt='' />
                                    </div>
                                    <div className="d-flex flex-column justify-content-center align-items-center" style={{ padding: 25 }}>
                                        <img src='/images/gift.png' alt='' width='250px' height='180px' />
                                        <h1 className="greet">Whoo hoo…. High Five! <br />
                                            <p> We’ve received your redemption request.{window.innerWidth > 767 ? <br /> : null} Check your registered mail box for rewards card details.</p></h1>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div >

            }
        </div>
    )
}
